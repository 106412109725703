export default {
  name: "CustomRetentionPeriod",
  data: () => ({
    showDataRetentionPeriodDialog : false,
    localDataRetentionPeriod      : null,
    isDataRetentionPeriodDuplicate: false,
    existsOnlyOneRetentionPeriod  : false
  }),
  props: {
    pCustomRetentionPeriods       : Array,
    pIsAddingDataRetentionPeriod  : Boolean,
    pDataRetentionPeriodAddError  : Object,
    pIsDataRetentionPeriodAdded   : Boolean,
    pIsRemovingDataRetentionPeriod: Boolean
  },
  emits: [
    "addDataRetentionPeriod",
    "resetDataRetentionPeriodAddError",
    "removeDataRetentionPeriod"
  ],
  computed: {
    retentionPeriodsToDisplay() {
      return this.pCustomRetentionPeriods
    },
    disableAddCustomRetentionPeriod() {
      if (this.localDataRetentionPeriod < 30 || this.pDataRetentionPeriodAddError?.type === "duplicate" || this.pIsRemovingDataRetentionPeriod) {
        return true
      }
      return false
    }
  },
  methods: {
    addDataRetentionPeriod() {
      this.$emit("addDataRetentionPeriod", this.localDataRetentionPeriod)
    },
    handleShowDataRetentionPeriodDialog() {
      this.showDataRetentionPeriodDialog = false
      this.localDataRetentionPeriod      = null
    },
    removeDataRetentionPeriod(id) {
      this.$emit("removeDataRetentionPeriod", id)
    }
  },
  watch: {
    pDataRetentionPeriodAddError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          if (newValue.field === "value" && newValue.type === "duplicate") {
            this.isDataRetentionPeriodDuplicate = true
            return
          }
        }
        this.isDataRetentionPeriodDuplicate = false
      }
    },
    localDataRetentionPeriod: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && this.isDataRetentionPeriodDuplicate) {
          this.$emit("resetDataRetentionPeriodAddError")
        }
      }
    },
    pIsDataRetentionPeriodAdded: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.localDataRetentionPeriod = null
        }
      }
    }
  }
}