import {
  DATA_RETENTION_RULES,
  DATA_RETENTION_OPTIONS,
  AI_ANONYMISE_ALLOWED_DATA_RETENTION_RULES,
  DATA_RETENTION_RULES_TO_DISPLAY_TOOLTIP
} from "@/constants"
import ThemisCustomRetentionPeriod from "@/components/custom-retention-period"

export default {
  name      : "DataRetentionRules",
  components: {
    ThemisCustomRetentionPeriod
  },
  data: () => ({
    updatingDataRetentionRuleId: null
  }),
  props: {
    pDataRetentionRules           : Array,
    pIsUpdatingDataRetentionRule  : Boolean,
    pIsAiAnonymisationEnabled     : Boolean,
    pCustomRetentionPeriods       : Array,
    pIsAddingDataRetentionPeriod  : Boolean,
    pDataRetentionPeriodAddError  : Object,
    pIsDataRetentionPeriodAdded   : Boolean,
    pIsRemovingDataRetentionPeriod: Boolean,
    pFields                       : Array
  },
  emits: [
    "updateDataRetentionRule",
    "addDataRetentionPeriod",
    "resetDataRetentionPeriodAddError"
  ],
  computed: {
    dataRetentionGeneralRules() {
      return [...this.pDataRetentionRules]
        .sort((firstDataRetentionRule, secondDataRetentionRule) =>
          firstDataRetentionRule.id - secondDataRetentionRule.id)
        .filter(dataRetentionRule => DATA_RETENTION_RULES.GENERAL.includes(dataRetentionRule.key))
        .map(dataRetentionRule => {
          if (dataRetentionRule.key === "Issue documents") {
            return { ...dataRetentionRule, key: "Documents" }
          }
          return dataRetentionRule
        })
    },
    dataRetentionReportRules() {
      return [...this.pDataRetentionRules]
        .sort((firstDataRetentionRule, secondDataRetentionRule) =>
          firstDataRetentionRule.id - secondDataRetentionRule.id)
        .filter(dataRetentionRule => DATA_RETENTION_RULES.REPORT.includes(dataRetentionRule.key))
        .map(dataRetentionRule => {
          if (dataRetentionRule.key === "Report documents") {
            return { ...dataRetentionRule, key: "Documents" }
          }
          return dataRetentionRule
        })
    },
    dataRetentionFieldRules() {
      return this.pDataRetentionRules.filter(dataRetentionRule =>
        dataRetentionRule.key === DATA_RETENTION_RULES.FIELD)
        .map(dataRetentionRule => {
          const field = this.fieldsMap.get(dataRetentionRule.fieldId)
          return {
            id   : dataRetentionRule.id,
            key  : field.systemName,
            value: dataRetentionRule.value,
            type : field.type
          }
        })
        .sort((firstDataRetentionField, secondDataRetentionField) =>
          firstDataRetentionField.key.localeCompare(secondDataRetentionField.key))
    },
    dataRetentionTables() {
      return [{
        rules  : this.dataRetentionGeneralRules,
        headers: this.$TABLES.DATA_RETENTION_RULES.headers(this.$t("1277"))
      },
      {
        rules  : this.dataRetentionReportRules,
        headers: this.$TABLES.DATA_RETENTION_RULES.headers(this.$t("1298"))
      },
      {
        rules  : this.dataRetentionFieldRules,
        headers: this.$TABLES.DATA_RETENTION_RULES.headers(this.$t("1310"))
      }]
    },
    fieldsMap() {
      const fieldsMap = new Map()
      for (const field of this.pFields) {
        fieldsMap.set(field.id, field)
      }
      return fieldsMap
    }
  },
  methods: {
    shouldDisplayToolTipForRule(ruleName) {
      return ruleName === DATA_RETENTION_RULES_TO_DISPLAY_TOOLTIP.MESSAGE_CONTENT
        && this.pIsAiAnonymisationEnabled
    },
    getToolTipText(headerText) {
      const toolTipTexts = {
        [this.$t("1277")]                                        : this.$t("1278"),
        [this.$t("1298")]                                        : this.$t("1299"),
        [this.$t("1310")]                                        : this.$t("1311"),
        [DATA_RETENTION_RULES_TO_DISPLAY_TOOLTIP.MESSAGE_CONTENT]: this.$t("1176")
      }

      return toolTipTexts[headerText]
    },
    dataRetentionOptions(item) {
      const isAiAnonymiseAllowed = this.pIsAiAnonymisationEnabled &&
        (AI_ANONYMISE_ALLOWED_DATA_RETENTION_RULES.GENERAL.includes(item.key) ||
          AI_ANONYMISE_ALLOWED_DATA_RETENTION_RULES.REPORT.includes(item.key) ||
          AI_ANONYMISE_ALLOWED_DATA_RETENTION_RULES.FIELD.TYPE.includes(item.type))

      return isAiAnonymiseAllowed
        ? [...DATA_RETENTION_OPTIONS, this.$t("1175")]
        : [...DATA_RETENTION_OPTIONS]
    },
    updateDataRetentionRule(id, option) {
      this.$emit("updateDataRetentionRule", { id, option })
      this.updatingDataRetentionRuleId = id
    },
    isDataRetentionRuleUpdating(ruleId) {
      return this.pIsUpdatingDataRetentionRule && this.updatingDataRetentionRuleId === ruleId
    },
    handleAddDataRetentionPeriod(dataRetentionPeriod) {
      this.$emit("addDataRetentionPeriod", dataRetentionPeriod)
    },
    resetDataRetentionPeriodAddError() {
      this.$emit("resetDataRetentionPeriodAddError")
    },
    getItemsPerPage(headers) {
      if (headers[0].text === this.$t("1310")) {
        return this.pFields.length
      }
    },
    handleRemoveRetentionPeriod(id) {
      this.$emit("removeDataRetentionPeriod", id)
    }
  }
}