import ThemisInput from "@/components/shared/input"
import ThemisDecision from "@/components/shared/decision"
import { SSO_CONFIGURATIONS } from "@/constants"

export default {
  name      : "SecurityConfigurations",
  components: {
    ThemisInput,
    ThemisDecision
  },
  data: () => ({
    isConfiguringDefaultSso     : false,
    identifierCopied            : false,
    replyUrlCopied              : false,
    showEnableDefaultSsoDialog  : false,
    localDefaultSsoConfiguration: {
      givenName  : undefined,
      email      : undefined,
      metadataUrl: undefined
    },
    timerForCopiedText: null
  }),
  props: {
    pIsDefaultSsoConfigured         : Boolean,
    pIsDefaultSsoEnabled            : Boolean,
    pClientUserPoolId               : String,
    pClientConfigurationId          : String,
    pDefaultSsoConfiguration        : Object,
    pIsSavingDefaultSsoConfiguration: Boolean,
    pIsDefaultSsoConfigurationSaved : Boolean,
    pIsEnablingDefaultSsoLogin      : Boolean,
    pIsDefaultSsoLoginEnabled       : Boolean,
    pDefaultSsoConfigurationError   : Object
  },
  computed: {
    helpCenterURL() {
      return process.env.VUE_APP_SSO_HELP_URL
    },
    identifier() {
      return `${SSO_CONFIGURATIONS.URN_AMAZON_COGNITO_SP}${this.pClientUserPoolId}`
    },
    replyUrl() {
      return `https://${this.pClientConfigurationId}.${SSO_CONFIGURATIONS.AUTH}.${SSO_CONFIGURATIONS.REGION}.${SSO_CONFIGURATIONS.AMAZONCOGNITO_SAML_2_IDPRESPONSE}`
    },
    isDefaultSsoConfiguredOrEnabled() {
      return this.pIsDefaultSsoConfigured || this.pIsDefaultSsoEnabled
    },
    firstTimeConfiguringDefaultSso() {
      return !this.isDefaultSsoConfiguredOrEnabled && this.isConfiguringDefaultSso
    },
    updatingDefaultSsoConfiguration() {
      return this.isDefaultSsoConfiguredOrEnabled && this.isConfiguringDefaultSso
    },
    hasErrorWhileSavingDefaultSsoConfiguration() {
      return !!this.pDefaultSsoConfigurationError
    }
  },
  methods: {
    submitDefaultSsoConfiguration() {
      this.$emit("saveDefaultSsoConfiguration", this.localDefaultSsoConfiguration)
    },
    cancelConfiguringDefaultSso() {
      if (this.pDefaultSsoConfiguration) {
        this.localDefaultSsoConfiguration = { ...this.pDefaultSsoConfiguration }
      } else {
        this.localDefaultSsoConfiguration = {
          givenName  : undefined,
          email      : undefined,
          metadataUrl: undefined
        }
      }
      this.isConfiguringDefaultSso = false
      this.identifierCopied        = false
      this.replyUrlCopied          = false
    },

    async copyIdentifier() {
      await navigator.clipboard.writeText(this.identifier)
      this.identifierCopied = true
      this.replyUrlCopied   = false
      if(this.timerForCopiedText){
        clearTimeout(this.timerForCopiedText)
      }
      this.timerForCopiedText = setTimeout(() => this.identifierCopied = false, 2000)
    },

    async copyReplyUrl() {
      await navigator.clipboard.writeText(this.replyUrl)
      this.replyUrlCopied   = true
      this.identifierCopied = false
      if(this.timerForCopiedText){
        clearTimeout(this.timerForCopiedText)
      }
      this.timerForCopiedText = setTimeout(() => this.replyUrlCopied = false, 2000)
    }
  },
  watch: {
    localDefaultSsoConfiguration: {
      immediate: false,
      deep     : true,
      handler  : function() {
        if (this.pDefaultSsoConfigurationError) {
          this.$emit("resetDefaultSsoConfigurationError")
        }
      }
    },
    pDefaultSsoConfiguration: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.localDefaultSsoConfiguration = {
            ...newValue
          }
        }
      }
    },
    pIsDefaultSsoConfigurationSaved: {
      immediate: false,
      handler  : function(newValue) {
        this.isConfiguringDefaultSso = !newValue
      }
    },
    pIsEnablingDefaultSsoLogin: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.ENABLE_DEFAULT_SSO_LOGIN.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.ENABLE_DEFAULT_SSO_LOGIN.pActions[1].buttonProps.loading  = newValue
      }
    },
    pIsDefaultSsoLoginEnabled: {
      immediate: false,
      handler  : function(newValue) {
        if (newValue) {
          this.showEnableDefaultSsoDialog = false
        }
      }
    }
  }
}