import ThemisDecision from "@/components/shared/decision"

export default {
  name      : "SystemAccessConfigurations",
  components: {
    ThemisDecision
  },
  data: () => ({
    userOrGroup      : undefined,
    accessToBeRemoved: undefined
  }),
  props: {
    pAccesses         : Array,
    pRoles            : Array,
    pUsers            : Array,
    pGroups           : Array,
    pIsLoadingAccesses: Boolean,
    pIsAddingAccess   : Boolean,
    pIsAccessAdded    : Boolean,
    pIsAccessRemoved  : Boolean,
    pIsRemovingAccess : Boolean

  },
  computed: {
    usersMap() {
      const usersMap = new Object()
      for (const user of this.pUsers) {
        usersMap[user.id] = user
      }
      return usersMap
    },
    groupsMap() {
      const groupsMap = new Object()
      for (const group of this.pGroups) {
        groupsMap[group.id] = group
      }
      return groupsMap
    },
    accessesForDisplay() {
      const accessesForDisplay = new Array()
      for (const access of this.pAccesses) {
        if (access.roleId === 1) {
          const accessForDisplay = {
            id: access.id
          }
          if (access.groupId) {
            accessForDisplay.groupId = access.groupId
            accessForDisplay.name    = this.groupsMap[access.groupId]?.name
            accessForDisplay.type    = "group"
          } else {
            const user              = this.usersMap[access.userId]
            accessForDisplay.userId = access.userId
            accessForDisplay.type   = "user"
            if (user) {
              accessForDisplay.name                  = user.name
              accessForDisplay.disabledButNotDeleted = user.disabledButNotDeleted
            }
          }
          accessesForDisplay.push(accessForDisplay)
        }
      }
      return accessesForDisplay
    },
    usersAndGroups() {
      const users                         = new Array()
      const groups                        = new Array()
      const usersWithAdministratorAccess  = new Array()
      const groupsWithAdministratorAccess = new Array()
      for (const access of this.accessesForDisplay) {
        if (access.groupId) {
          groupsWithAdministratorAccess.push(access.groupId)
        } else {
          usersWithAdministratorAccess.push(access.userId)
        }
      }
      for (const user of this.pUsers) {
        if (!usersWithAdministratorAccess.includes(user.id) && user.enabled) {
          users.push({
            id  : `${this.$CONSTANTS.ROLE_ASSIGNMENT_CATEGORY.USER}_${user.id}`,
            name: user.name
          })
        }
      }
      for (const group of this.pGroups) {
        if (!groupsWithAdministratorAccess.includes(group.id)) {
          groups.push({
            id  : `${this.$CONSTANTS.ROLE_ASSIGNMENT_CATEGORY.GROUP}_${group.id}`,
            name: group.name
          })
        }
      }
      return [{ "header": "Users" }].concat(users).concat([{ "header": "Groups" }]).concat(groups)
    }
  },
  methods: {
    assignRole() {
      const userGroupDetails = this.userOrGroup.split("_")
      if (userGroupDetails[0] === this.$CONSTANTS.ROLE_ASSIGNMENT_CATEGORY.USER) {
        this.$emit("addAccess", {
          userId: +userGroupDetails[1],
          roleId: 1
        })
      } else {
        this.$emit("addAccess", {
          groupId: +userGroupDetails[1],
          roleId : 1
        })
      }
    }
  },
  watch: {
    pIsAccessAdded: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.userOrGroup = undefined
        }
      }
    },
    pIsAccessRemoved: {
      handler: function(newValue) {
        if (newValue) {
          this.accessToBeRemoved = undefined
        }
      }
    },
    pIsRemovingAccess: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.REMOVE_ACCESS.pActions[1].buttonProps.loading  = newValue
        this.$DECISIONS.REMOVE_ACCESS.pActions[0].buttonProps.disabled = newValue
      }
    }
  }
}